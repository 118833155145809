import queryString from "query-string";
import { MapKing } from "./js/config";
import aes from "./js/aes.json" assert { type: "json" };
import * as CryptoJS from "crypto-js";
import MK_MAP from "./js/map";
const parsed = queryString.parse(location.search);
const token = parsed.token;

function verifyToken(token) {
  try {
    const aes_key = aes.key;
    const token_iv = CryptoJS.enc.Base64.parse(token)
      .toString(CryptoJS.enc.Utf8)
      .split("-");
    if (token_iv.length != 2) {
      return false;
    }
    const aes_encrypted = token_iv[0];
    const aes_iv = CryptoJS.enc.Latin1.parse(atob(token_iv[1]));
    //const aes_iv = token_iv[1];

    const aes_decrypted = CryptoJS.AES.decrypt(
      //如果服务端返回的base64字符串，需要先解码
      //CryptoJS.enc.Base64.parse(aes_encrypted).toString(CryptoJS.enc.Utf8),
      //如果服务端直接返回的加密字符串，不需要解码
      aes_encrypted,
      CryptoJS.enc.Utf8.parse(aes_key),
      {
        iv: aes_iv,
        mode: CryptoJS.mode.CBC, //要与php端一致
      }
    );
    //console.log(aes_decrypted); //解码后是一个wordArray数组
    //console.log("JS MapAPI: = ", CryptoJS.enc.Utf8.stringify(aes_decrypted)); //把二进制对象转为utf8字符串：这是一个测试AES加密
    //console.log(token_iv);
    return true;
  } catch (error) {
    return false;
  }
}
if (token) {
  if (verifyToken(token)) {
    console.log("token is valid");
    //加密成功，显示地图
    const mk_map = new MK_MAP();
    mk_map.initMap();
  } else {
    console.log("token is exist");
  }
} else {
  console.log("token is not exist");
}
//=> {foo: 'bar'}
//获取url上的参数key，验证key是否正确
