import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { MapKing } from "./config";

const attributions =
  '<a href="https://www.mapking.com" target="_blank">&copy; MapKing</a> ';

const mk_my_basemap = new TileLayer({
  title: "MapKing MY BaseMap",
  visible: true,
  //minResolution: 1,           // to display the visible between max and min resolution
  maxResolution: 20,
  source: new TileWMS({
    attributions: attributions,
    url: MapKing.mk_geoserver_url + "/wms",
    params: {
      FORMAT: "image/png",
      CRS: "EPSG:4326",
      VERSION: "1.1.1",
      LAYERS: "my_webmap:my_basemap",
      exceptions: "application/vnd.ogc.se_inimage",
      TILED: "true",
    },
    serverType: "geoserver",
  }),
});

export default mk_my_basemap;
