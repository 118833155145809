import { get } from "ol/proj";
const mk_geoserver_url = "http://103.246.160.202:40044/geoserver/";
const MapKing = {
  mk_version: "mk-apikey-v1.0.0",
  mk_geoserver_url: "http://103.246.160.202:40044/geoserver/",
  mk_bounds: [
    99.63977813720703, 0.855000972747803, 119.26972961425781, 7.380556106567383,
  ],
  mk_extent: [
    99.63977813720703, 0.855000972747803, 119.26972961425781, 7.380556106567383,
  ],
  mk_proj: get("EPSG:4326"),
};
export { MapKing, mk_geoserver_url };
