import "../css/ol.css";
import "../css/mapking.css";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import ScaleLine from "ol/control/ScaleLine";
import OSM from "ol/source/OSM";
import {
  DragRotateAndZoom,
  defaults as defaultInteractions,
} from "ol/interaction";
import { FullScreen, defaults as defaultControls } from "ol/control";

//MapKing的配置文件，需要引入
import { MapKing } from "./config";
import { mousePositionControl, zoomControl, zoomSlider } from "./control";
import mk_my_basemap from "./wmslayer";

export default class MK_MAP {
  constructor(option = {}) {
    this.option = option;
  }
  initMap() {
    const mk_mapview = new View({
      center: [3.146862099135947, 101.69601873353119],
      //extent: MapKing.mk_extent,
      projection: MapKing.mk_proj,
      zoom: 3,
      minZoom: 1,
      maxZoom: 42.5,
      zoomFactor: 1.4,
    });

    const mk_map = new Map({
      target: "map",
      // controls: [
      //   new ScaleLine({
      //     units: "metric",
      //     bar: true,
      //     text: true,
      //   }),
      // ],
      //controls: defaultControls().extend([new FullScreen()]),
      //interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      layers: [
        new TileLayer({
          source: new OSM(),
          opacity: 1.0,
        }),
        mk_my_basemap,
      ],
      view: mk_mapview,
    });

    //显示坐标
    //mk_map.addControl(mousePositionControl);
    mk_map.addControl(zoomControl);
    mk_map.addControl(zoomSlider);

    mk_map.getView().fit(MapKing.mk_extent, mk_map.getSize());

    mk_map.on("rendercomplete", function (e) {
      var zoomLevel = mk_map.getView().getZoom();
      var zoomRounded = Math.round(zoomLevel * 10) / 10;
      //console.log(zoomRounded);

      function rad_to_deg(radians) {
        return radians * (180 / Math.PI);
      }
      var map_rotation = mk_map.getView().getRotation();
      //console.log(map_rotation);
      //console.log(rad_to_deg(map_rotation));
    });

    //mk_mapview.adjustZoom(3, [98.84096, 1.32355]);
    console.log(MapKing.mk_geoserver_url);

    return mk_map;
  }
}
