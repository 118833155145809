import MousePosition from "ol/control/MousePosition";
import { createStringXY } from "ol/coordinate.js";
import Zoom from "ol/control/Zoom";
import ZoomSlider from "ol/control/ZoomSlider";

//显示坐标显示在地图上
const mousePositionControl = new MousePosition({
  /*className: 'custom-mouse-position',
    target: document.getElementById('xylocation'),*/
  coordinateFormat: createStringXY(6),
});

const zoomControl = new Zoom();
const zoomSlider = new ZoomSlider();

export { mousePositionControl, zoomControl, zoomSlider };
